import { useMemo } from "react"
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api"
import locations from "../constants/locations"

const API_KEY = "AIzaSyDiYq6YOqEiVd_3307tjmtqctPWS5KTmC4"
const center = { lat: 44.1765704, lng: 20.4247779 }

export default function Map() {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: API_KEY })

  if (!isLoaded) return <div>Loading..</div>

  return (
    <GoogleMap zoom={8} center={center} mapContainerClassName="map-container">
      {locations.map((el, i) => <MarkerF key={`marker-${i}`} icon="/images/map_pin.png" position={{ lat: el.coords[0], lng: el.coords[1] }} title={el.name} />)}
    </GoogleMap>
  )
}
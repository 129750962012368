import { useCallback, useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useWindowSize } from "usehooks-ts"

const variants = {
  hidden: { opacity: 0, scale: 0.2, zIndex: 1 },
  visible: { opacity: 1, scale: 1, zIndex: 2 }
}

export default function Slider({ title, description, items, folder }) {
  let pageSize = 1
  let [index, setIndex] = useState(0)
  let [interactable, setInteractable] = useState(true)
  let { width } = useWindowSize()

  if (width > 1600) pageSize = 3;
  else if (width > 1200) pageSize = 2;

  let visibleItems = []
  for (let i = 0; i < pageSize; i++) visibleItems.push(items[(index + i) % items.length])

  let resetBtn = useCallback(() => {
    setInteractable(false);
    setTimeout(() => setInteractable(true), 400)
  }, [])

  return (
    <div className="slider-container">
      <button className="prev" onClick={() => { setIndex(prev => (prev - 1 + items.length) % items.length); resetBtn() }} disabled={!interactable}><img src="/images/prev.png" /></button>
      <div className="slider">
        <AnimatePresence mode="popLayout">
          {visibleItems.map(el => <motion.div key={`${folder}item-${el.name}`} layout className="item" variants={variants} initial="hidden" animate="visible" exit="hidden" transition={{ type: "spring", duration: .4 }}>
            <img src={`/images${folder}${el.image}`} />
            <h3>{el.name}</h3>
            <p>{el.description}</p>
          </motion.div>)}
        </AnimatePresence>
      </div>
      <button className="next" onClick={() => { setIndex(prev => (prev + 1) % items.length); resetBtn() }} disabled={!interactable}><img src="/images/next.png" /></button>
    </div>
  )
}
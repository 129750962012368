const locations = [
  { name: "Sigma Plus, Vrnjačka broj 1", coords: [43.6232790472192, 20.8938641] },
  { name: "Stop šop, Karađorđeva 3", coords: [44.01293379805937, 20.913635842328677] },
  { name: "Пеца, Lava Tolstoja 4-6", coords: [43.15697754948526, 22.583284815342655] },
  { name: "Gardenn Restaurant and Apartments, Zrenjaninski put 156a", coords: [44.88984302902889, 20.459802215342652] },
  { name: "A&M&D, 93 IVE LOLE RIBARA", coords: [45.24462071066768, 19.391974571164333] },
  { name: "Cara Dušana 40", coords: [44.75830373997182, 19.69799861534265] },
  { name: "Karađorđeva 57", coords: [44.754497411481935, 19.698507442328673] },
  { name: "Norveška 14", coords: [44.749088940858755, 19.693321699999995] },
  { name: "TRAFIKA TARA-17, Nikole Tesle 27", coords: [45.37574691614687, 20.062683615342657] },
  { name: "Тржни центар „Слобода“, Novosadski put", coords: [45.04863812995335, 20.080540121164336] },
  { name: "Pere Ninkovića 39", coords: [44.98986151298715, 20.16907512883566] },
  { name: "Patrijarha Pavla 21", coords: [44.96681805721186, 20.281312557671328] },
  { name: "Trg Branka Radičevića 3", coords: [45.20290207035398, 19.93360837116433] },
  { name: "Cara Lazara 89, Kovin", coords: [44.74369139592265, 20.97904001534265] },
  { name: "Cara Lazara 105, Kovin", coords: [44.74273553926057, 20.978865828835666] },
  { name: "Sentandrejski put 59", coords: [45.289608626038564, 19.82471721534266] },
  { name: "Svetog Save 55, Vladimirci", coords: [44.616318760036556, 19.783660786506992] },
  { name: "Jugoslovenske Armije 13", coords: [45.24841405331393, 19.384573986506993] },
  { name: "Vojvode Stepe Stepanovića 111", coords: [45.41404296273047, 19.698433415342656] },
  { name: "Vojvode Putnika 21", coords: [45.41528104712932, 19.70267727301398] },
  { name: "Maršala Tita", coords: [45.4676855416789, 19.465670557671327] },
  { name: "Moby Dick, Dimitrja Tucovića 3 stadion Vojvodine", coords: [45.24727410308172, 19.843713217192303] },
  { name: "Beogradska 1, Ugrinovci", coords: [44.87581526743643, 20.1877518] },
  { name: "Fruškogorska 6", coords: [45.24429046565158, 19.846760928835664] },
  { name: "E-TECH, brace badzaka 2", coords: [44.44636345519251, 20.68691270184965] },
  { name: "Elektronske cigarete GTIVapes, Kneginje Milice 106", coords: [43.97507693718867, 21.264413886506993] },
  { name: "Vojvode Stepe 11, Inđija 22320", coords: [45.05010770527926, 20.082020813493006] },
  { name: "Ustanička 239", coords: [44.78431807650319, 20.51800152883567] },
  { name: "Puff bar, Svetozara Markovića 43", coords: [44.80458385160493, 20.465072215342655] },
  { name: "Da Shop Novi Beograd", coords: [44.826923600497686, 20.397635657671334] },
  { name: "PURA VIDA, Bulevar vojvode Putnika 36", coords: [44.79452831963967, 20.44794011534266] },
  { name: "Tobacco shop, Jaše Tomića 11", coords: [45.118108771957104, 21.297352701849647] },
  { name: "Cirkus Novi Sad", coords: [45.254034620047335, 19.846574273013978] },
  { name: "Stendal Naš, Lončarska 1", coords: [45.25843136255292, 19.848436928835667] },
  { name: "Trgovačka 9a, Beograd", coords: [44.75943141017907, 20.41562021349301] },
  { name: "Dragoslava Jovanovića 13", coords: [44.811838988621865, 20.463707128835665] },
  { name: "Romanijska 9l", coords: [44.84213514266513, 20.394551286506996] },
  { name: "Bulevar kralja Aleksandra 354", coords: [44.793136441190335, 20.505013515342654] },
  { name: "Cara Dušana 201", coords: [44.85878576392162, 20.378268598150353] },
  { name: "Karađorđeva 4a", coords: [44.839314134533744, 20.415938086506987] },
  { name: "Jurija Gagarina 149a, Beograd", coords: [44.80197879552698, 20.387489286506995] },
  { name: "Brankova 1", coords: [44.81429170333886, 20.45687288835664] },
  { name: "Vidikovački venac 73", coords: [44.74116992305002, 20.418904159520974] },
  { name: "Bulevar umetnosti 23", coords: [44.81779882639322, 20.413754673013983] },
  { name: "Jurija Gagarina 221", coords: [44.797587978105305, 20.377134173013978] },
  { name: "Nehruova 53", coords: [44.79956634286985, 20.383806842328667] },
  { name: "Jurija Gagarina 151", coords: [44.80207479533064, 20.386955744178323] },
  { name: "Zemunska 5", coords: [44.81115183663031, 20.383941188356633] },
  { name: "Aleksandra Dubčeka 14", coords: [44.83739998666962, 20.410488299999997] },
  { name: "Bulevar maršala Tolbuhina 2", coords: [44.83137131412574, 20.418482299999994] },
  { name: "Laki Ley Shop, Novi Pazar", coords: [43.14101571395614, 20.521351515342655] },
  { name: "Dërvarit, Mitrovicë", coords: [42.89287265567512, 20.861996415342652] },
  { name: "Slatki Butik, Čika Ljubina 6", coords: [44.26909730637706, 19.88547098835664] },
]

export default locations
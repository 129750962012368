import { useState } from "react"
import { motion } from "framer-motion"
import { useWindowSize } from "usehooks-ts"

const navStates = {
  closed: {
    x: -250,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  opened: {
    x: 0
  }
}

const parentStates = {
  closed: {

  },
  opened: {
    transition: { staggerChildren: 0.07, delayChildren: 0.15 }
  }
}

const itemStates = {
  closed: {
    opacity: 0,
    x: -50
  },
  opened: {
    opacity: 1,
    x: 0
  }
}

const sections = ["Home", "Neon", "Ape", "Ape Zero", "Party", "Maxi", "Midi", "Mini", "Lokacije"]

function Link({ name, onClick }) {
  return <motion.li variants={itemStates}><a href={`#${name.replace(" ", "").toLowerCase()}`} onClick={onClick}>{name}</a></motion.li>
}

export default function Navigation() {
  let [opened, setOpened] = useState(false)
  let { width } = useWindowSize()
  let linePos = 13

  if (width < 992) linePos = 10

  return (
    <motion.nav initial="closed" animate={opened ? "opened" : "closed"} variants={navStates}>
      <img className="logo" src="/images/white-logo.png" />
      <motion.ul variants={parentStates}>
        {sections.map(el => <Link key={`id::${el}`} name={el} onClick={() => setOpened(false)} />)}
      </motion.ul>
      <button onClick={() => setOpened(!opened)}><motion.div animate={{ rotate: opened ? 45 : 0, y: opened ? linePos : 0 }} /><motion.div animate={{ scale: opened ? 0 : 1 }} /><motion.div animate={{ rotate: opened ? -45 : 0, y: opened ? -linePos : 0 }} /></button>
    </motion.nav>
  )
}
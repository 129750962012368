let neon = {
  folder: "/neon",
  items: [
    { name: "Strawberry Ice Cream", description: "Sladoled od jagode", image: "/neon-01.jpg" },
    { name: "Blueberry Ice", description: "Ledena borovnica", image: "/neon-02.jpg" },
    { name: "Gummy Bear", description: "Gumene mede", image: "/neon-03.jpg" },
    { name: "Grape Ice", description: "Ledeno grozdje", image: "/neon-04.jpg" },
    { name: "Strawberry", description: "Jagoda", image: "/neon-05.jpg" },
    { name: "Strawberry Peach", description: "Jagoda u kombinaciji sa breskvom", image: "/neon-06.jpg" },
    { name: "Watermelon Sweet Sour Candy", description: "Lubenica u kombinaciji sa slatko kiselim bombonama", image: "/neon-07.jpg" },
    { name: "Red Bull", description: "Ukus osvezavajuceg Red Bull-a", image: "/neon-08.jpg" },
  ]
}

let ape = {
  folder: "/ape",
  items: [
    { name: "Cool Mint", description: "Slatki mint", image: "/puff-01.jpg" },
    { name: "Blue Razz Lemonade", description: "Borovnica i malina u kombinaciji sa limunom", image: "/puff-02.jpg" },
    { name: "Mamba", description: "Tropsko voće u kombinaciji sa jagodom", image: "/puff-03.jpg" },
    { name: "Cotton Candy", description: "Secerna Vuna", image: "/puff-04.jpg" },
    //{ name: "Guava Ice", description: "Hladna Guava", image: "/puff-05.jpg" },
    //{ name: "Lychee Ice", description: "Hladne Lychee bobice", image: "/puff-06.jpg" },
    { name: "Sour Bites", description: "Kiseli ugriz", image: "/puff-07.jpg" },
    { name: "Blue Slushy", description: "Kupina", image: "/puff-08.jpg" },
    { name: "Banana Ice", description: "Ledena banana", image: "/puff-09.jpg" },
  ]
}

let ape0 = {
  folder: "/ape0",
  items: [
    { name: "Ice Cola", description: "Ledeno popularno piće Koka kola", image: "/puff-0.jpg" },
    { name: "Blue Razz", description: "Borovnica i malina", image: "/puff-2.jpg" },
    { name: "Mamba", description: "Tropsko voće u kombinaciji sa jagodom", image: "/puff-1.jpg" },
    { name: "Pink Lemonade", description: "Limunada sa malinom", image: "/puff-3.jpg" },
  ]
}

let party = {
  folder: "/party",
  items: [
    { name: "Long Island", description: "Sladak ukus koka-kole i ruma", image: "/puff-01.jpg" },
    { name: "Mai Tai", description: "Svima poznat ukus slatkog koktela", image: "/puff-02.jpg" },
    { name: "Strawberry Margarita", description: "Fin i osvežavajući ukus jagode sa citrusnom notom", image: "/puff-03.jpg" },
    { name: "Mojito", description: "Citrusna nota limete i osvežavajući ukus mente", image: "/puff-04.jpg" },
    { name: "Sex On The Beach", description: "Aroma likera od breskve u kombinaciji sa ananasom i pomorandžom", image: "/puff-05.jpg" },
    { name: "Sparking Watermelon", description: "Strujni udar lubenice na sva vaša čula", image: "/puff-06.jpg" },
    { name: "Bahama Mama", description: "Sladak koktel sa ananasom i kokosom", image: "/puff-07.jpg" },
    { name: "Citrus Punch", description: "Kombinacija pomorandze , grejpa i limuna", image: "/puff-08.jpg" },
    { name: "Cosmopolitan", description: "Koktel sa narom i limunom", image: "/puff-09.jpg" },
    { name: "Ginger Beer", description: "Pivo i djumbir", image: "/puff-10.jpg" },
  ]
}

let maxi = {
  folder: "/maxi",
  items: [
    { name: "Apple Ice", description: "Ledena jabuka", image: "/puff-01.jpg" },
    { name: "Lychee Ice", description: "Hladne Lychee bobice", image: "/puff-02.jpg" },
    { name: "Mamba", description: "Tropsko voće u kombinaciji sa jagodom", image: "/puff-03.jpg" },
    { name: "Mango", description: "Ukus manga", image: "/puff-04.jpg" },
    { name: "Strawberry Milk Shake", description: "Ukus milksejka od jagode", image: "/puff-05.jpg" },
    { name: "Cool Mint", description: "Slatki mint", image: "/puff-06.jpg" },
    { name: "Raspberry Blueberry", description: "Malina u kombinaciji sa borovnicom", image: "/puff-07.jpg" },
    { name: "Strawberry Watermelon", description: "Jagoda u kombinaciji sa lubenicom", image: "/puff-08.jpg" },
  ]
}

let midi = {
  folder: "/midi",
  items: [
    { name: "Crisp Apple", description: "Jabuka", image: "/puff-01.jpg" },
    { name: "Blue Razz", description: "Malina u kombinaciji sa borovnicom", image: "/puff-02.jpg" },
    { name: "Green Grape", description: "Belo grozdje", image: "/puff-03.jpg" },
    { name: "Lush Ice", description: "Ukus lubenice", image: "/puff-04.jpg" },
    { name: "Honey Melon", description: "Slatki ukus dinje", image: "/puff-05.jpg" },
    { name: "Strawberry Burst", description: "Udar jagode", image: "/puff-06.jpg" },
    { name: "Banana", description: "Banana", image: "/puff-07.jpg" },
    { name: "Forest Fruit", description: "Sumsko voce", image: "/puff-08.jpg" },
    { name: "Spearmint", description: "Osvezavajuci mint", image: "/puff-09.jpg" },
  ]
}

export { neon, ape, ape0, party, maxi, midi }
import { useWindowSize } from "usehooks-ts"

export default function Home() {
    let { height } = useWindowSize()

    return (
        <section id="home" style={{ height: `${height}px` }} className="home">
            <a className="main" href="#neon">
                <div className="bg" />
                <div className="content neon">
                    <img className="n-logo" style={{ maxWidth: 721 }} src="/images/logo-neon.png" />
                    <img className="n-features-main" style={{ maxWidth: 867 }} src="/images/features-main.png" />
                    <img className="n-features" src="/images/features.png" />
                </div>
            </a>
            <div className="side">
                <a href="#ape">
                    <div className="bg"></div>
                    <div className="content">
                        <img style={{ maxWidth: 435, maxHeight: 252, height: "90%", width: "90%", objectFit: "contain" }} src="/images/logo-ape.png" />
                    </div>
                </a>
                <a href="#party">
                    <div className="bg"></div>
                    <div className="content">
                        <img style={{ maxWidth: 402, maxHeight: 261, height: "90%", width: "90%", objectFit: "contain" }} src="/images/logo-party.png" />
                    </div>
                </a>
                <a href="#midi">
                    <div className="bg"></div>
                    <div className="content">
                        <img style={{ maxHeight: 362, maxWidth: 217, height: "90%", width: "90%", objectFit: "contain" }} src="/images/logo-midi.png" />
                    </div>
                </a>
                <a href="#apezero">
                    <div className="bg"></div>
                    <div className="content">
                    <img style={{ maxWidth: 435, maxHeight: 308, height: "90%", width: "90%", objectFit: "contain" }} src="/images/logo-ape-zero.png" />
                    </div>
                </a>
            </div>
        </section>
    )
}
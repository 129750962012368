import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Slider from './components/Slider';
import { neon, ape, ape0, party, maxi, midi } from './constants/puffs'
import Map from './components/Map';

function App() {
  return (
    <>
      <Home />

      <section id="neon">
        <h2>Unipuff Neon</h2>
        <p className='desc'>Unipuff Neon je cigareta sa najnovijom tehnologijom koja obezbedjuje veću količinu dima i uvek ujednačen ukus naših prelepih aroma. Ova cigareta ima mogućnost dopune baterije , C ulaz. Neon ima 12 mililitara nikotinske soli što je dovoljno za čak i do 3000 dimova, pa je idealan za zahtevnije vejpere.</p>
        <Slider {...neon} />
      </section>
      
      <section id="ape">
        <h2>Unipuff APE (5%)</h2>
        <p className='desc'>Unipuff Ape je zasnovan na najnovijem aparatu sa "no leaking" tehnologijom, baterija snage 850 mAh u kombinaciji sa najkvalitetnijim isparivacem, daju bogat dim i uživanje u jedinstvenim aromama. Zapremina Ape e cigarete je 4.8ml što je dovoljno za najviše 1200 povlačenja.</p>
        <Slider {...ape} />
        <h2 className='m-t-50' id="apezero">Unipuff APE ZERO (0%)</h2>
        <p className='desc'>Unipuff Zero je zasnovan na najnovijem aparatu sa "no leaking" tehnologijom, baterija snage 850 mAh u kombinaciji sa najkvalitetnijim isparivacem, daju bogat dim i uživanje u jedinstvenim aromama. Zapremina Zero e cigarete je 4.8ml što je dovoljno za najviše 1200 povlačenja. Ovaj proizvod ne sadrži nikotin koji stvara zavisnost.</p>
        <Slider {...ape0} />
      </section>

      <section id="party">
        <h2>Unipuff Party</h2>
        <p className='desc'>Seriju Party aroma je nas tim proizveo u saradnji sa vrhunskim strunjacima za nikotinske soli i jedinstveni smo na globalnom nivou. Zapremina Party e cigarete je 4.8ml što je dovoljno za najviše 1200 povlačenja.</p>
        <Slider {...party} />
      </section>

      <section id="maxi">
        <h2>Unipuff Maxi</h2>
        <Slider {...maxi} />
      </section>

      <section id="midi">
        <h2>Unipuff Midi</h2>
        <p className='desc'>Unipuff Midi je cigareta prefinjenog dizajna. Svaka aroma je pažljivo birana i u potpunosti će zadovoljiti sva vaša čula i potrebu za nikotinom.</p>
        <Slider {...midi} />
      </section>

      <section id="footer">
        <h2 id='mini'>Unipuff Mini</h2>
        <p className='desc'>Mamba, Banana Ice, Apple Ice, Watermelon, Strawberry Watermelon, Exotic Beach, Strawberry Banana, Peach Ice, Pina Colada, Pink Lemonade</p>
        <img className="mini-img" src="/images/mini.jpg" />

        <h2 className='m-t-50' id='lokacije'>Lokacije</h2>
        <p className='desc'>Nase proizvode mozete kupiti na vise od 50 lokacija sirom Srbije</p>
        <Map />
      </section>
    </>
  );
}

export default App;
